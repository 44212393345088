import React, { Suspense, useState } from "react";
import { WWAObjOne } from "../components/WhoWeAre/Data";
import { WWDObjOne } from "../components/WhatWeDo/Data";
import { StructuralData } from "../components/Structural/Data";
import "./Home.css";

const HeroAnimated = React.lazy(() => import("../components/HeroAnimate"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const Sidebar = React.lazy(() => import("../components/Navbar/Sidebar"));
const WhoWeAre = React.lazy(() => import("../components/WhoWeAre"));
const Structural = React.lazy(() => import("../components/Structural"));
const WhatWeDo = React.lazy(() => import("../components/WhatWeDo"));
const Workshop = React.lazy(() => import("../components/Workshop"));
const Project = React.lazy(() => import("../components/Project"));
const Contact = React.lazy(() => import("../components/Contact"));
const Collaboration = React.lazy(() => import("../components/Collaboration"));
const Footer = React.lazy(() => import("../components/Footer"));

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Home">
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar toggle={toggle} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Sidebar isOpen={isOpen} toggle={toggle} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HeroAnimated />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <WhoWeAre {...WWAObjOne} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Structural {...StructuralData} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <WhatWeDo {...WWDObjOne} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Workshop />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Project />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Contact />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Collaboration />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Home;
