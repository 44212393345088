import React from "react";
import { useHistory as historys } from "react-router";
import {
  ThanksContainer,
  ThanksWrapper,
  ThanksText,
  TextContainer,
  ThanksText2,
} from "./thanksElements";

const thanksmail = () => {
  const history = historys();

  setTimeout(() => {
    history.push("/");
  }, 3000);

  return (
    <>
      <ThanksContainer>
        <ThanksWrapper>
          <TextContainer>
            <ThanksText>Thanks You 🙌</ThanksText>
            <ThanksText2>the message successfully sumbitted</ThanksText2>
          </TextContainer>
        </ThanksWrapper>
      </ThanksContainer>
    </>
  );
};

export default thanksmail;
