import React, { Suspense, useState } from "react";
import styled from "styled-components";
import images from "../../../../../images/Featured Projects/PIEP/images.webp";
import images2 from "../../../../../images/Featured Projects/PIEP/images2.webp";
import images3 from "../../../../../images/Featured Projects/PIEP/images3.webp";
import images4 from "../../../../../images/Featured Projects/PIEP/images4.webp";
import images5 from "../../../../../images/Featured Projects/PIEP/images5.webp";
import img from "../../../../../images/Featured Projects/Top.webp";
import { Helmet } from "react-helmet-async";

const Navbar = React.lazy(() => import("../Navbar"));
const Sidebar = React.lazy(() => import("../Navbar/Sidebar"));

export const AllProjectContainers = styled.div`
  background: #0f0f0f;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
`;

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  background-image: url(${img});
  padding-top: 4rem;
`;

export const Titles = styled.h1`
  color: #fff;
  padding-top: 3.5rem;
  text-align: center;
`;

export const ProjectText = styled.div`
  max-width: 500px;
  padding-top: 7rem;

  @media (max-width: 575.98px) {
    padding-top: 2rem;
  }
`;

export const SubTitle = styled.h2`
  color: #e85508;
  padding-bottom: 1rem;
  text-align: center;
`;

export const ListDesc = styled.p`
  color: #fff;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 160%;
`;

export const Text = styled.p`
  color: #e2e2e2;
  max-width: 400px;
  padding-bottom: 0.5rem;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 160%;
  text-align: center;

  @media (max-width: 575.98px) {
    max-width: 350px;
    text-align: left;
  }
`;

export const Bolder = styled.p`
  font-weight: 600;
  color: #fff;
  padding-top: 0.5rem;
  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 160%;
`;

export const ProjectIMG = styled.div`
  padding-top: 1rem;
`;

export const IMG = styled.img`
  height:100%
  fit-content: cover;
  min-width: 330px;

  @media (max-width: 575.98px) {
    max-width: 450px;
    max-height: 250px;
  }
`;

const PertaminaInternasionalEP = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>Featured Project | PT Pertamina Internasional EP</title>
        <meta
          name="description"
          content="The featured project of PT Pertamina Internasional EP from PT ICONIQ MULTI DIMENSI - ICONIQ Interior"
        />
        <link
          rel="canonical"
          href="https://iconiq-interior.co.id/project/featured-project/pertamina_internasional_EP"
        />
      </Helmet>
      <AllProjectContainers>
        <Suspense fallback={<div>Loading...</div>}>
          <Sidebar isOpen={isOpen} toggle={toggle} />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar toggle={toggle} />
        </Suspense>
        <TitleContainer>
          <Titles>PT Pertamina Internasional EP</Titles>
        </TitleContainer>
        <ProjectContainer>
          <ProjectText>
            <SubTitle>Project Details :</SubTitle>
            <ListDesc>
              <Text>
                <Bolder>Project :</Bolder> Fit Out PT Pertamina Internasional EP
              </Text>
              <Text>
                <Bolder>Business Core :</Bolder> Oil and Gas Company
              </Text>
              <Text>
                <Bolder>Area :</Bolder> 2000 sqm
              </Text>
              <Text>
                <Bolder>Location :</Bolder> Patra Jasa Office Tower 10th - 12th Floor
              </Text>
              <Text>
                <Bolder>Concept :</Bolder> Modern Minimalist with touch of corporate colors as vocal points in interior finishing and furniture.
              </Text>
            </ListDesc>
          </ProjectText>
          <ProjectIMG>
            <IMG
              src={images}
              alt="Iconiq Interior featured project PT Pertamina Internasional EP"
              title="Featured Project | PT Pertamina Internasional EP"
              loading="eager"
            />
          </ProjectIMG>
          <ProjectIMG>
            <IMG
              src={images2}
              alt="Iconiq Interior featured project PT Pertamina Internasional EP"
              title="Featured Project | PT Pertamina Internasional EP"
              loading="lazy"
            />
          </ProjectIMG>
          <ProjectIMG>
            <IMG
              src={images3}
              alt="Iconiq Interior featured project PT Pertamina Internasional EP"
              title="Featured Project | PT Pertamina Internasional EP"
              loading="lazy"
            />
          </ProjectIMG>
          <ProjectIMG>
            <IMG
              src={images4}
              alt="Iconiq Interior featured project PT Pertamina Internasional EP"
              title="Featured Project | PT Pertamina Internasional EP"
              loading="lazy"
            />
          </ProjectIMG>
          <ProjectIMG>
            <IMG
              src={images5}
              alt="Iconiq Interior featured project PT Pertamina Internasional EP"
              title="Featured Project | PT Pertamina Internasional EP"
              loading="lazy"
            />
          </ProjectIMG>
        </ProjectContainer>
      </AllProjectContainers>
    </>
  );
};

export default PertaminaInternasionalEP;
