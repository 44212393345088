export const WWAObjOne = {
  id: "whoweare",
  lightBg: true,
  lightText: false,
  lightTextDesc: true,
  topLine: "Who We Are",
  headline: "PT ICONIQ MULTI DIMENSI",
  description:
    "PT ICONIQ MULTI DIMENSI is a professional interior contractor and furniture manufacturing company founded in 2015 in Jakarta, Indonesia.",
  description2:
    "we have 7 years experienced in interior construction and furniture manufacturing, as for interior design and multimedia IMD will liaise with professional interior design firm and multimedia system integrator (SI). Many clients are come from oil gas company, commercial, institution, banks and others.",
  description3:
    "Until now we provide in the fields for architecture, contractors, interiors, and furniture to meet your needs.",
  imgStart: false,
  img: require("../../images/WhoWeAre/whoweare_image.webp"),
  alt: "Struktur Organisasi | PT ICONIQ MULTI DIMENSI",
  dark: false,
  primary: true,
  darkText: false,
};
