import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const ThanksContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 700px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #fff;
`;

export const ThanksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  height: 150px;
  }
`;

export const ThanksText = styled.h1`
  margin: 0.25rem;
  color: #e85508;
`;
export const ThanksText2 = styled.h3`
  margin: 0.25rem;
  color: #2c2c2c;
`;

export const ThanksButton = styled(LinkR)`
  text-align: center;
  font-family: "Work Sans", sans-serif;
  border: none;
  border-radius: 5px;
  max-width: 150px;
  max-height: 150px;
  padding: 12px 24px;
  color: #fff;
  background-color: #e85508;
  font-weight: 600;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -3%;

  &:hover {
    color: #e85508;
    background-color: transparent;
    border: 1px solid #e85508;
  }

  @media screen and (max-width: 575.98px) {
    font-size: 14px;
    padding: 10px 12px;
    max-width: 140px;
  }
`;
