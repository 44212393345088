import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import { CertContainer } from "./CertElements";

const Navbar2 = React.lazy(() => import("../Navbar2"));

const Sidebar = React.lazy(() => import("../Navbar2/Sidebar"));

const ISOCertificate = React.lazy(() =>
  import("./ISOCertificate/ISOCertificate")
);

const CertAppreciation = React.lazy(() =>
  import("./CertAppreciation/CertAppreciation")
);
const EChsems = React.lazy(() => import("./EChsems/EChsems"));

const Certificate = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Helmet>
        <title>PT ICONIQ MULTI DIMENSI - ICONIQ Interior | Certification</title>
        <meta
          name="description"
          content="All Certification of PT ICONIQ MULTI DIMENSI including ISO, EChsems, and Appreciation Certificate"
        />
        <link
          rel="canonical"
          href="https://iconiq-interior.co.id/certification"
        />
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Sidebar isOpen={isOpen} toggle={toggle} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar2 toggle={toggle} />
      </Suspense>
      <CertContainer id="Certificate">
        <Suspense fallback={<div>Loading...</div>}>
          <ISOCertificate />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <CertAppreciation />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <EChsems />
        </Suspense>
      </CertContainer>
    </>
  );
};

export default Certificate;
